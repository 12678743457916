import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
// import HomeView from '../views/HomeView.vue';
import WorkSection from '@/components/WorkSection.vue';
import SkillSection from '@/components/SkillSection.vue';
import AchievementSection from '@/components/AchievementSection.vue';
// import EducationSection from '@/components/EducationSection.vue';
import ProjectsSection from '@/components/ProjectsSection.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Work',
    component: WorkSection
  },
  {
    path: '/projects',
    name: 'Projects',
    component: ProjectsSection
  },
  {
    path: '/skills',
    name: 'Skills',
    component: SkillSection
  },
  // {
  //   path: '/education',
  //   name: 'Education',
  //   component: EducationSection
  // },
  {
    path: '/achievements',
    name: 'Achievements',
    component: AchievementSection
  },
  {
    path: '/:catchAll(.*)', // Matches any route not defined above
    redirect: '/',
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.fullPath.includes('#')) {
    const newPath = to.fullPath.split('#')[1]; // Extract the part after #
    next(newPath || '/'); // Redirect to the new path or home page
  } else {
    next(); // Proceed as normal
  }
});

export default router
